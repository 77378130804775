@media only screen and (max-width: 1355px) {
  .formWidth {
    width: 100%;
  }
}

@media only screen and (max-width: 940px) {
  .loginForm {
    width: fit-content !important;
    align-items: center !important;
    padding: 2rem;
    background-color: white;
    margin: 5rem auto 1rem auto;
    border-radius: 2rem;
    box-shadow: 0 0 4px lightgray;
  }
  .loginForm .formDetails {
    align-items: center !important;
    padding: 0 !important;
  }
  .headen {
    font-size: 2.2rem !important;
  }
}

@media only screen and (max-width: 800px) {
  .input,
  .phoneIpt .phoneComp {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    height: 45px !important;
  }

  .loginForm section {
    width: 100% !important;
  }
  .loginForm {
    min-width: 500px !important;
  }
}
@media only screen and (max-width: 630px) {
  .loginForm {
    min-width: 100% !important;
  }
}
