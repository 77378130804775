@media only screen and (max-width: 744px) {
  .userStats {
    width: 100%;
    flex-direction: column !important;
    height: auto;
    padding: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  .img {
    width: 100%;
    align-items: center !important;
    display: none;
  }
  .stats {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    width: 100% !important;
    margin: 0;
    padding: 0 !important;
    height: fit-content !important;
  }
  .stats section {
    margin: 0 !important;
    padding: 0.9rem 0.2rem !important;
    border: 1px solid rgb(0, 29, 87, 0.1);
    height: fit-content !important;
  }
}

@media only screen and (max-width: 710px) {
  .stats {
    grid-template-columns: repeat(1, 1fr);
  }
  .stats section div img {
    width: 25px;
  }
  .stats section div p {
    text-align: left;
    font-size: small !important;
  }
  .stats section div small {
    text-align: right;
    font-size: small !important;
  }
  .stats section div h1 {
    font-size: large !important;
    text-align: right;
    width: 100%;
  }
  .stats section div:nth-child(2) {
    display: none;
  }
}
