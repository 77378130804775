.orderButton,
.menuIcon,
.actionBtn {
  display: none;
}
@media only screen and (max-width: 1400px) {
  .orderButton,
  .actionBtn {
    display: flex;
  }
}

@media only screen and (max-width: 1020px) {
  .menuIcon {
    display: flex;
  }
}
