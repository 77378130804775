@media only screen and (max-width: 1000px) {
  .sideImg {
    min-width: 300px !important;
  }
}

@media only screen and (max-width: 940px) {
  .sideImg {
    display: none;
  }
}

@media only screen and (max-width: 1355px) {
  .mobAds {
    width: 310px;
  }
  .storeImg {
    flex-direction: column;
    height: 110px;
    justify-content: space-between;
  }
  .storeImg * {
    margin: 0;
  }
}

@media only screen and (max-width: 1220px) {
  .moblieImg {
    width: 90%;
  }
}
