.logo {
  display: none;
}
.formContent {
  /* border: 2px solid blue; */
}
@media only screen and (max-height: 630px) {
  .wrapper {
    padding: 0rem 2rem !important;
  }
}
@media only screen and (max-width: 940px) {
  .logo {
    display: block;
  }
  .authFooter {
    justify-content: center !important;
    margin-bottom: 2rem;
  }
  .authFooter small {
    text-align: center !important;
  }
  .mobileAds {
    display: flex;
    align-items: center;
    margin: 2rem auto;
    width: fit-content;
  }
  .mobileAds * {
    text-align: center !important;
  }
  .wrapper {
    padding: 0 !important;
    align-items: center !important;
  }
}
@media only screen and (max-width: 630px) {
  .formContent {
    padding: 0rem 1rem !important;
  }
}
