.MobTitle,
.MobSubDesc,
.MobDate {
  display: none;
}

@media only screen and (max-width: 766px) {
  .headen {
    font-size: 1.8rem !important;
  }
  .newsCard {
    display: unset !important;
    align-items: flex-start !important;
    padding: 1rem 0.5rem;
    width: 500px;
  }
  .newsCard div:nth(1) {
    width: 100% !important;
  }
  .img {
    min-width: 100% !important;
    height: 200px;
    box-shadow: 0 0 5px 1px rgb(0, 0, 0, 0.1);
    padding: 0 0.4rem !important;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  .subDes {
    display: none !important;
  }
  .MobTitle,
  .MobSubDesc,
  .MobDate {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
  .MobDate {
    justify-content: flex-end !important;
  }
  .MobTitle {
    display: block;
    font-size: 1.2rem !important;
    margin-top: 0.2rem !important;
  }
}

@media only screen and (max-width: 500px) {
  .newsCard {
    width: 100%;
  }
  .MobTitle {
    display: block;
    font-size: 1rem !important;
  }
}
