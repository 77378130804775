@media only screen and (max-width: 800px) {
  .OrderContainer {
  }

  .orderInner {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .headen {
    font-size: 1.3rem !important;
  }
  .amountHead h1 {
    font-size: 1.1rem !important;
  }

  .amountHead input {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 500px) {
  .order {
    min-width: 100% !important;
  }
}
