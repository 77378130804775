.banner .img {
  height: 150px;
  min-width: 250px;
}
.banner aside:first-child {
  width: 100%;
}
.banner aside:first-child h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem !important;
}
.banner aside:first-child .btns {
  justify-content: flex-start;
}
.banner aside:first-child button {
  min-width: 140px !important;
  min-height: 50px !important;
  margin-right: 0.5rem;
}
.banner aside:first-child button * {
  font-weight: 500 !important;
  font-size: 1rem;
}

@media only screen and (max-width: 766px) {
  .banner aside:first-child h1 {
    font-size: 2rem !important;
  }
  .header {
    font-size: 1.5rem !important;
  }
}
@media only screen and (max-width: 683px) {
  .banner .img {
    height: 100px;
    min-width: 180px;
  }
}
@media only screen and (max-width: 610px) {
  .banner .img {
    display: none;
  }
}
@media only screen and (max-width: 572px) {
  /* Where I paused ......... */
}
