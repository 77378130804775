.mediaIcons * {
  box-sizing: border-box !important;
}

.whatsapp:hover {
  color: rgb(16, 136, 122) !important;
  background: white !important;
  border: 2px solid rgb(16, 136, 122) !important;
}

.whatsapp:hover i {
  color: rgb(16, 136, 122) !important;
}

.facebook:hover {
  color: rgb(23, 116, 235) !important;
  background: white !important;
  border: 2px solid rgb(23, 116, 235) !important;
}

.facebook:hover i {
  color: rgb(23, 116, 235) !important;
}

.instagram:hover {
  color: rgb(142, 45, 181) !important;
  background: white !important;
  border: 2px solid rgb(142, 45, 181) !important;
}

.instagram:hover i {
  color: rgb(142, 45, 181) !important;
}

.twitter:hover {
  color: rgb(30, 156, 234) !important;
  background: white !important;
  border: 2px solid rgb(30, 156, 234) !important;
}

.twitter:hover i {
  color: rgb(30, 156, 234) !important;
}

@media only screen and (max-width: 1436px) {
  .supportContainer {
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 1276px) {
  .supportMain {
    /* flex-direction: column-reverse !important; */
  }

  .supportImage {
    height: 350px;
  }

  .supportImage img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 1042px) {
  .supportImage {
    height: 250px;
  }

  .mediaIcons {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}

@media only screen and (max-width: 750px) {
  .textContent h1 {
    font-size: 2.5rem !important;
  }

  .textContent h3 {
    font-size: 1.3rem !important;
  }

  .supportImage {
    height: 200px;
  }
}

@media only screen and (max-width: 601px) {
  .supportImage {
    display: none !important;
  }

  .textContent h1 {
    font-size: 3rem !important;
  }

  .supportContainer {
    padding: 2rem 0.5rem !important;
  }
}
