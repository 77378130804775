.noticeText h1:nth-child(2) {
  display: none;
}

@media only screen and (max-width: 1592px) {
  .headText {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 770px) {
  .noticeImg {
    display: none;
  }
  .noticeContent {
    padding: 1.5rem;
    justify-content: flex-start;
  }
  .noticeText h1 {
    font-size: 1.1rem;
  }
  .noticeText h1:nth-child(1) {
    display: none;
  }
  .noticeText h1:nth-child(2) {
    display: block;
  }
  .noticeText button {
    margin: 0.5rem 0 0 0;
  }
  .market {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.8rem;
  }
  .market aside {
    margin: 0;
  }
  .textContent {
    padding-left: 1rem;
    min-width: 100%;
  }
  .subText {
    width: 300px;
  }
  .img {
    top: 0.05rem;
    right: 0rem;
    width: 30rem !important;
  }
}

@media only screen and (max-width: 614px) {
  .noticeText h1 {
    font-size: 0.8rem;
    line-height: normal;
  }
  .noticeText button {
    font-size: 0.8rem;
    padding: 0 !important;
  }
  .headText {
    font-size: 1.5rem;
  }
  .subText {
    width: 180px;
    font-size: 10px;
  }
  .textContent {
    padding-left: 0.3rem;
  }
  .noticeContent2 {
    height: 250px;
  }
  .img {
    right: 0rem;
    width: 30rem !important;
    bottom: 0;
    top: unset;
  }
}
