.rightSide2 {
  display: none;
}
@media only screen and (max-width: 1400px) {
  .rightSide {
    display: none !important;
  }
  .rightSide2 {
    display: flex;
  }
}
