@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::placeholder {
  font-size: 0.8rem;
}

@media only screen and (max-width: 766px) {
  * {
    font-size: 0.95rem;
  }
}

.reverseright {
  animation-name: reverseright;
  animation-duration: 1200ms;
  animation-delay: 0;
}

@keyframes reverserigh {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.reverseLeft {
  animation-name: reverseLeft;
  animation-duration: 1200ms;
  animation-delay: 0;
}

@keyframes reverseLef {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.reverseScale {
  animation-name: reverseScale;
  animation-duration: 500ms;
  animation-delay: 0;
}

@keyframes reverseScale {
  from {
    transform: scale(100%);
  }

  to {
    transform: scale(0%);
  }
}
.widthAnimate {
  animation-name: widthAnimate;
  animation-duration: 4s;
  animation-delay: 0;
}

@keyframes widthAnimate {
  from {
    width: 0;
  }

  to {
    width: fit-content;
  }
}

.shema {
  background: linear-gradient(to right, #e5e7eb79, #009f9522, #e5e7eb7a);
  animation: shema 2s 0s infinite;
}

@keyframes shema {
  0% {
    background-position: left;
    background-size: 200%;
  }

  80% {
    background-position: right;
    background-size: 200%;
  }

  100% {
    background-position: left;
    background-size: 200%;
  }
}

.loadanimate {
  animation-name: loadanimate;
  animation-duration: 1s;
  animation-delay: 0;
  animation-iteration-count: infinite;
}

@keyframes loadanimate {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
